<template>
	<WalletMultiButton :connecting="connecting" v-if="!isWeb2" @connect="onConnected" :wallets="wallets" dark></WalletMultiButton>
</template>

<script>
import {useWallet, WalletMultiButton} from "@alphabatem/vue2-wallet-adapter";
import {AlphaWalletAdapter} from "@solana/wallet-adapter-alpha"
import {BackpackWalletAdapter} from "@solana/wallet-adapter-backpack"
import {CoinbaseWalletAdapter} from "@solana/wallet-adapter-coinbase"
import {PhantomWalletAdapter} from "@solana/wallet-adapter-phantom"
import {SolflareWalletAdapter} from "@solana/wallet-adapter-solflare"
import Auth from '../../js/api/auth'

import { mapActions } from "vuex"

export default {
	name: "MultiWallet",
	components: {
		WalletMultiButton
	},
	props: {
		button_text: {
			type: String,
			default: function () {
				return "Connect Wallet"
			}
		}
	},
	data() {
		return {
			connecting: false,
			wallet: null,
			wallets: [
				new AlphaWalletAdapter(),
				new PhantomWalletAdapter(),
				new SolflareWalletAdapter(),
				new CoinbaseWalletAdapter(),
				new BackpackWalletAdapter(),
			]
		}
	},
	watch: {
		wallet: {
			handler: function (w) {
				console.log("Wallet changed", this.wallet)
				if (!this.wallet.connected) {
					console.log("Wallet not connected")
						this.attemptConnect()

					return
				}

				console.log("Watch connect")
				this.onConnected()
			},
			deep: true
		},
	},
	computed: {
		isWeb2: function () {
			return this.$store.state.wallet.address && this.$store.state.wallet.address.indexOf("Web2") !== -1
		},
	},
	methods: {
		...mapActions({
			getProfile: 'profile/getProfile',
		}),
		onConnected() {
			this.connecting = false
			console.log("Connected")
			this.$emit("wallet-connected", this.wallet.publicKey.toString()); //Fire connected event
			this.$store.commit('wallet/set_wallet_address', this.wallet.publicKey.toString());
			this.$store.commit('wallet/set_wallet_connected', true);
			this.$emit("connected", true)

			try {
				Auth.connect(this.wallet.publicKey.toString())
			} catch (e) {
			}

			this.getProfile(this.$store.state.wallet.address).catch(e => {})
		},
		attemptConnect() {
			if (!this.wallet.wallet)
				return

			this.connecting = true
			this.wallet.connect()
		}
	},
	mounted() {
		if (this.isWeb2)
			return

		this.wallet = useWallet()
		this.attemptConnect()

		if (this.wallet.connected) {
			this.onConnected()
		}
	}
}
</script>

<style scoped>
</style>
