var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isWeb2
    ? _c("WalletMultiButton", {
        attrs: { connecting: _vm.connecting, wallets: _vm.wallets, dark: "" },
        on: { connect: _vm.onConnected },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }